import React from 'react';
import ReactDOM from 'react-dom/client';
import { Router } from './component/rotas';
import "./index.css"
import { PageCadastro } from './page/cadastro';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  <Router />
  // <PageCadastro />

);


