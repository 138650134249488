import useFetch from '../../../component/conexao';
import { Titulo } from '../../../component/titulo';
import { CardEmpresa } from '../cardEmpresa';

export function Empresa() {
    const { data, loadingHoje, errorHoje } = useFetch('https://goopedir.com/ws/v1/cliente.php');
    return (<>
        <div style={{ padding: '20px', }}>
            <Titulo title="Clientes" />
        </div>
        <div style={{ padding: '20px', height: '460px', overflowX: 'auto' }}>

            {data?.map((dados, index) => (
                <CardEmpresa key={index} data={dados} />
            ))}

        </div>

    </>)
}