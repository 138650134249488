import useFetch from "../../../component/conexao";
import { addLeadingZeros, dataJaPassou, formatDate, formatNumber, formatNumberInteiro, isMobile } from "../../../component/function";
import { Titulo } from "../../../component/titulo"
import CardInfo from "../cardInfo";



const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center', // Alinha horizontalmente ao centro
    gap: '20px',
    margin: '20px'

};


const cardContainerStyle = {
    minWidth: isMobile() ? '100%' : '300px',
    maxWidth: 'calc(25%)', // Para 4 cards por linha, considerando o gap de 20px    
};

const cardStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '170px',
    padding: '20px',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
    boxSizing: 'border-box',
    width: '100%',
    marginTop: '20px'
};

const iconStyle = {
    flex: '0 0 50px',
    height: '50px',
    marginRight: '20px'
};

const infoStyle = {
    flex: '1'
};

const boldTextStyle = {
    fontWeight: 'bold',
    marginBottom: '5px'
};

const valueStyle = {
    textAlign: 'right'
};

const TextStyle = {
    fontSize: '10px',
    color: 'rgb(194, 0, 29)',
};

const CardInfoLocal = ({ Prefix, Numero, Cliente, Vencimento, Total }) => (
    <div style={cardStyle}>
        <div style={infoStyle}>
            <div style={TextStyle}>Fatura #</div>
            <div><b>{Prefix}{Vencimento.slice(0, 4)}/{addLeadingZeros(Numero, 6)}</b></div>

            <div style={TextStyle}>Cliente</div>
            <div><b>{Cliente}</b></div>
            <div style={TextStyle}>Vencimento</div>
            <div><b>{formatDate(Vencimento)}</b></div>
            <div style={TextStyle}>Total</div>
            <div><b>R$ {formatNumber(Total)}</b></div>
            <div style={TextStyle}>Status</div>
            <div><b>{dataJaPassou(formatDate(Vencimento)) ? 'A Vencer' : 'Vencida'}</b></div>


        </div>
    </div>
);



function SomaTotal(data) {
    let tot = 0;

    for (let i = 0; i < data.length; i++) {
        tot += parseFloat(data[i].total)
    }

    return tot;

}

function CardFatura({ type }) {
    const { data, loadingHoje, errorHoje } = useFetch(`https://goopedir.com/ws/v2/fatura-em-aberto.php?type=${type}`);
    return (<>
        {data ? <>
            <div style={cardContainerStyle}>
                <CardInfo iconSrc="../img/money-96.png" Descricao="Total a Receber" Quantidade={0} Total={SomaTotal(data)} />


                {data?.map((dados, index) => (
                    <CardInfoLocal key={index} Prefix={dados.prefix} Vencimento={dados.duedate} Numero={dados.number} Cliente={dados.company} Total={dados.total} />
                ))}

            </div>
        </> : null}

    </>)
}


export function Faturas({ type, titulo }) {
    return (<>
        <div style={{ padding: '20px', }}>
            <Titulo title={titulo} />
        </div>
        <div style={containerStyle}>
            <CardFatura type={type} />
        </div>
    </>)
}