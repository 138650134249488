


const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    backgroundColor: 'rgb(168, 0, 28)',
    padding: '0 16px'
};

const logoStyle = {
    width: '126px',
    height: '36px',
    marginRight: '8px'
};

const textStyle = {
    fontSize: '16px',
    color: '#FFFFFF',
};

export function Header() {
    return (<div style={headerStyle}>
        <img src="../img/logo-goopedir-b.png" alt="Logo" style={logoStyle} />
        <span style={textStyle}>Painel Administrativo</span>
    </div>)
}