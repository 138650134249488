import { formatNumber, isMobile } from "../../../component/function";
import useFetch from '../../../component/conexao';
import { Titulo } from "../../../component/titulo";




const cardStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '150px',
    padding: '20px',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
    boxSizing: 'border-box',
    width: '100%',
};

const iconStyle = {
    flex: '0 0 50px',
    height: '50px',
    marginRight: '20px'
};

const infoStyle = {
    flex: '1'
};

const boldTextStyle = {
    fontWeight: 'bold',
    marginBottom: '5px'
};

const valueStyle = {
    textAlign: 'right'
};

const CardInfo = ({ iconSrc, Mes, Total }) => (
    <div style={cardStyle}>
        <img src={iconSrc} alt="Icon" style={iconStyle} />
        <div style={infoStyle}>
            <div style={boldTextStyle}>{Mes}</div>
            <div>Total</div>
            <div style={valueStyle}><b>R$ {formatNumber(Total)}</b></div>
        </div>
    </div>
);

const cardContainerStyle = {
    minWidth: isMobile() ? '100%' : '300px',
    maxWidth: 'calc(25%)', // Para 4 cards por linha, considerando o gap de 20px
};

const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center', // Alinha horizontalmente ao centro
    gap: '20px',
    margin: '20px'

};




const CardRecorrente = () => {
    const { data, loadingHoje, errorHoje } = useFetch('https://goopedir.com/ws/v2/recorrente.php');

    return (<div style={cardContainerStyle}>
        {data ? <CardInfo iconSrc="../img/money-96.png" Mes="Fatura Recorrente" Total={data[0].total ? data[0].total : 0} /> : null}

    </div>)
};

export function Faturamento() {
    const { data, loadingHoje, errorHoje } = useFetch('https://goopedir.com/ws/v2/faturamento.php');

    return (<>

        {data ? <>
            <div style={{ padding: '20px', }}>
                <Titulo title="Faturamento" />
            </div>
            <div style={containerStyle}>
                <div style={cardContainerStyle}>
                    <CardInfo iconSrc="../img/calendário-48.png" Mes="Mês Pasado" Total={data[0].total ? data[0].total : 0} />
                </div>
                <div style={cardContainerStyle}>
                    <CardInfo iconSrc="../img/hoje-96.png" Mes="Mês Atual" Total={data[1].total ? data[1].total : 0} />
                </div>

                <CardRecorrente />
            </div>
        </> : null}
    </>)
}