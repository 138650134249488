import { Header } from "../../component/header";
import { Empresa } from "../component/Empresa";
import { Faturamento } from "../component/cardFaturamento";
import { CardCardsContainer } from "../component/cardsContainer";
import { Faturas } from "../component/faturas";





export function PageInicio() {
    return (<>
        {/* <div className="classCenter">
            <div className="grupoCenter">
                <div className="grupoHeader">
                    <div className="grupoInput">

                        <div className="input-box">
                            <label for="cnpj">CNPJ</label>
                            <input type="text" id="cnpj" name="cnpj" />
                        </div>

                        <div className="input-box">
                            <label for="name">Nome</label>
                            <input type="text" id="name" name="name" />
                        </div>

                        <div className="input-box">
                            <label for="link">Link</label>
                            <input type="text" id="link" name="link" />
                        </div>


                        <div className="input-box">
                            <label for="email">E-Mail</label>
                            <input type="text" id="email" name="email" />
                        </div>

                        <div className="input-box">
                            <label for="celular">Celular</label>
                            <input type="text" id="celular" name="celular" />
                        </div>

                        <div className="input-box">
                            <label for="rua">Rua</label>
                            <input type="text" id="rua" name="rua" />
                        </div>

                        <div className="input-box">
                            <label for="bairro">Bairro</label>
                            <input type="text" id="bairro" name="bairro" />
                        </div>

                        <div className="input-box">
                            <label for="cidade">Cidade</label>
                            <input type="text" id="cidade" name="cidade" />
                        </div>

                        <div className="input-box">
                            <label for="uf">Estado</label>
                            <input type="text" id="uf" name="uf" />
                        </div>

                        <div className="input-box">
                            <label for="cep">CEP</label>
                            <input type="text" id="cep" name="cep" />
                        </div>  <div className="input-box">
                            <label for="color-primary">Cor Principal</label>
                            <input type="color" id="color-primary" name="color-primary" />
                        </div>

                        <div className="input-box">
                            <label for="color-text">Cor Texto</label>
                            <input type="color" id="color-text" name="color-text" />
                        </div>

                        <div className="input-box">
                            <label for="logo">Logo</label>
                            <input type="file" id="logo" name="logo" />
                        </div> 

                    </div>
                </div>
            </div>
        </div> */}
        <Header />

        <CardCardsContainer />

        <Faturamento />

        <Empresa />
        <Faturas type="FAT-" titulo="Faturas Pendentes" />
        <Faturas type="TAX-PIX-" titulo="Taxas Pendentes" />
    </>)
}