// useFetch.js
import { useState, useEffect } from 'react';
import axios from 'axios';

function useFetch(url) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        axios.get(url)
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                setError(error);
            });
    }, [url]); // Dependência do URL, então refetch quando o URL muda.

    return { data, loading, error };
}

export default useFetch;




export async function PostData(url, body) {
    const response = await axios.post(url, body);
    // console.log(response.data)

}