import React from 'react';
import CardInfo from '../cardInfo';
import useFetch from '../../../component/conexao';
import { isMobile } from '../../../component/function';
import { Titulo } from '../../../component/titulo';


const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center', // Alinha horizontalmente ao centro
    gap: '20px',
    margin: '20px'

};


const cardContainerStyle = {
    minWidth: isMobile() ? '100%' : '300px',
    maxWidth: 'calc(25%)', // Para 4 cards por linha, considerando o gap de 20px
};

const CardHoje = () => {
    const { data, loadingHoje, errorHoje } = useFetch('https://goopedir.com/ws/v1/pedidos_hoje.php');
    return (
        data != null ? <>
            <div style={cardContainerStyle}>
                <CardInfo iconSrc="../img/time-96.png" Descricao="Hoje" Quantidade={data[0]?.quantidade ? data[0]?.quantidade : 0} Total={data[0]?.total ? data[0]?.total : 0} />
            </div>
        </> : null

    )
}

const CardPix = () => {
    const { data, loadingHoje, errorHoje } = useFetch('https://goopedir.com/ws/admin/pix.php');

    function SomaMPQtd() {
        return parseFloat(data[0]?.quantidade ? data[0]?.quantidade : 0) + parseFloat(data[1]?.quantidade ? data[1]?.quantidade : 0)
    }

    function SomaMPTotal() {
        return parseFloat(data[0]?.mp ? data[0]?.mp : 0) + parseFloat(data[1]?.mp ? data[1]?.mp : 0)
    }

    return (
        data != null ? <>
            <div style={cardContainerStyle}>
                <CardInfo iconSrc="../img/mercado-pago-96.png" Descricao="Mercado Pago" Quantidade={SomaMPQtd()} Total={SomaMPTotal()} />
            </div>
            <div style={cardContainerStyle}>
                <CardInfo iconSrc="../img/pix-96.png" Descricao="Processado" Quantidade={data[0]?.quantidade ? data[0]?.quantidade : 0} Total={data[0]?.total ? data[0]?.total : 0} />
            </div>
            <div style={cardContainerStyle}>
                <CardInfo iconSrc="../img/pix-aguardando-96.png" Descricao="Aguardando" Quantidade={data[1]?.quantidade ? data[1]?.quantidade : 0} Total={data[1]?.total ? data[1]?.total : 0} />
            </div>
        </> : null

    )
}


export function CardCardsContainer() {
    const { data, loadingHoje, errorHoje } = useFetch('https://goopedir.com/ws/v1/dados_dashboard.php');


    return (<>
        <div style={{ padding: '20px', }}>
            <Titulo title="DashBoard" />
        </div>
        <div style={containerStyle}>
            {data != null ? <>
                <div style={cardContainerStyle}>
                    <CardInfo iconSrc="../img/chart-94.png" Descricao="Total" Quantidade={data[0]?.qtd_pedido ? data[0]?.qtd_pedido : 0} Total={data[0]?.total_pedido ? data[0]?.total_pedido : 0} />
                </div>
                <div style={cardContainerStyle}>
                    <CardInfo iconSrc="../img/web-96.png" Descricao="Site" Quantidade={data[0]?.qtd_pedido_site ? data[0]?.qtd_pedido_site : 0} Total={data[0]?.total_pedido_site ? data[0]?.total_pedido_site : 0} />
                </div>
                <div style={cardContainerStyle}>
                    <CardInfo iconSrc="../img/register-96.png" Descricao="PDV" Quantidade={data[0]?.qtd_pedido_pdv ? data[0]?.qtd_pedido_pdv : 0} Total={data[0]?.total_pedido_pdv ? data[0]?.total_pedido_pdv : 0} />
                </div>
            </> : null}
            <CardHoje />

            {/* Repita <div style={cardContainerStyle}> para adicionar mais cards */}
        </div>
        <div style={{ padding: '20px', }}>
            <Titulo title="Relátorios Integração" />
        </div>
        <div style={containerStyle}>
            <CardPix />
        </div>
    </>)
}
