export function formatNumberInteiro(value) {
    return new Intl.NumberFormat('pt-BR').format(value);
}

export function formatNumber(value) {
    return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value);
}


export function isMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

export function dataJaPassou(dataString) {
    const dataAtual = new Date();
    const dataFornecida = new Date(dataString);


    // Compare as datas usando getTime() para obter os valores de data em milissegundos
    return dataFornecida.getTime() < dataAtual.getTime();
}

export function formatDate(dateString) {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
}

export function addLeadingZeros(number, length) {
    return String(number).padStart(length, '0');
}
