import { useState, useEffect } from "react";
import { dataJaPassou, isMobile } from "../../../component/function";
import { PostData } from "../../../component/conexao";



const cardStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '190px',
    padding: '20px',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
    boxSizing: 'border-box',
    width: '100%',
    marginBottom: '10px'
};

const cardStyleBloqueado = {
    display: 'flex',
    alignItems: 'center',
    height: '180px',
    padding: '20px',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
    boxSizing: 'border-box',
    width: '100%',
    marginBottom: '10px',
    backgroundColor: 'rgba(0,0,0,0.5)',
    filter: 'blur(0.6px)'
};

const iconStyleOn = {
    // flex: '0 0 150px',
    width: '100px',
    height: '100px',
    marginRight: '20px',
    borderRadius: '150px',
    border: '5px solid green'
};

const iconStyleOff = {
    // flex: '0 0 150px',
    width: '100px',
    height: '100px',
    marginRight: '20px',
    borderRadius: '150px',
    border: '5px solid rgb(194, 0, 29)'
};

const infoStyle = {
    flex: '1'
};

const boldTextStyle = {
    fontWeight: 'bold',
    marginBottom: '5px'
};

const TextStyle = {
    fontSize: '10px',
    color: 'rgb(194, 0, 29)',
};

const baseTextStyle = {
    filter: 'blur(5px)', // Isso irá desfocar o texto por padrão
    transition: 'filter 0.3s', // Isso suaviza a transição do desfoque
    cursor: 'pointer' // Isso muda o cursor para uma "mão" para indicar interatividade
};

const hoverTextStyle = {
    filter: 'none' // Remove o desfoque quando o mouse estiver sobre o elemento
};

const StyleAbertoOpen = {
    backgroundColor: 'green',
    borderRadius: '10px 0px 0px 10px',
    width: '80px',
    color: 'white',
    border: '1px solid rgba(0,0,0,0.5)'
}

const StyleAbertoClose = {
    backgroundColor: 'rgba(207, 207, 207,0.5)',
    borderRadius: '10px 0px 0px 10px',
    width: '80px',
    border: '1px solid rgba(0,0,0,0.5)'
}

const StyleFechadoOpen = {
    backgroundColor: 'rgb(168, 0, 28)',
    borderRadius: '0px 10px 10px 0px',
    color: 'white',
    width: '80px',
    border: '1px solid rgba(0,0,0,0.5)'
}

const StyleFechadoClose = {
    backgroundColor: 'rgba(207, 207, 207,0.5)',
    borderRadius: '00px 10px 10px 00px',
    width: '80px',
    border: '1px solid rgba(0,0,0,0.5)'
}



export function CardEmpresa({ data }) {
    const [hoverClientId, setHoverClientId] = useState(false);
    const [hoverClientSecurity, setHoverClientSecurity] = useState(false);
    const [DataVencimento, setDataVencimento] = useState((data.empresa_data_renovacao));
    // console.log(data)

    function convertDateFormat(dateString) {
        const parts = dateString.split('/');
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
    }

    const [StatusLoja, setStatusLoja] = useState(data.status_loja == 1);

    function Abrir() {
        let body = { usuario: data.user_id, campo: "status_loja", valor: 1 }
        PostData('https://goopedir.com/ws/v2/alteracao-empresa.php', body);
        setStatusLoja(true)
    }

    function Fechar() {
        let body = { usuario: data.user_id, campo: "status_loja", valor: 0 }
        PostData('https://goopedir.com/ws/v2/alteracao-empresa.php', body);
        setStatusLoja(false)
    }

    useEffect(() => {

        if (convertDateFormat(data.empresa_data_renovacao) != DataVencimento) {
            if (DataVencimento != '0000-00-00') {
                if (DataVencimento != '2023-01-01') {
                    // console.log(DataVencimento)
                    let body = { usuario: data.user_id, campo: "empresa_data_renovacao", valor: `"${DataVencimento}"` }
                    PostData('https://goopedir.com/ws/v2/alteracao-empresa.php', body);
                }


            } else {
                setDataVencimento('2023-01-01');
            }
        }
    }, [DataVencimento]);


    return (<>
        <div style={dataJaPassou(data.empresa_data_renovacao) ? cardStyleBloqueado : cardStyle}>
            <img src={`https://fotos.goopedir.com/fotos/empresa/${btoa(data.user_id)}`} alt="Icon" style={data.site > 2 ? iconStyleOff : iconStyleOn} />
            <div style={infoStyle}>
                <div style={TextStyle}>Empresa</div>
                <p style={boldTextStyle}>{data.nome_empresa}</p>
                {isMobile() ? null : <>
                    <div>
                        <div style={TextStyle}>Client ID</div>
                        <p
                            style={hoverClientId ? hoverTextStyle : baseTextStyle}
                            onMouseEnter={() => setHoverClientId(true)}
                            onMouseLeave={() => setHoverClientId(false)}
                        >
                            {data.client_id}
                        </p>
                        <div style={TextStyle}>Client Security</div>
                        <p
                            style={hoverClientSecurity ? hoverTextStyle : baseTextStyle}
                            onMouseEnter={() => setHoverClientSecurity(true)}
                            onMouseLeave={() => setHoverClientSecurity(false)}
                        >
                            {data.client_security}
                        </p>

                    </div>
                </>}

                <div style={TextStyle}>Vencimento</div>
                {/* <p style={boldTextStyle}>{data.empresa_data_renovacao}</p> */}
                <input type="date" value={DataVencimento} onChange={(e) => (setDataVencimento(e.target.value))} style={{ backgroundColor: 'transparent', color: 'black', border: 'none', fontWeight: 'bold', fontSize: '16px' }}></input>
                <div style={TextStyle}>Status Site</div>
                <div style={{ display: 'flex', textAlign: 'center' }}><div onClick={Abrir} style={StatusLoja == 1 ? StyleAbertoOpen : StyleAbertoClose}>Aberto</div><div style={StatusLoja == 1 ? StyleFechadoClose : StyleFechadoOpen} onClick={Fechar}>Fechado</div></div>
            </div>
        </div></>)
}