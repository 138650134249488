// CardInfo.js
import React from 'react';
import { formatNumber, formatNumberInteiro } from '../../../component/function';

const cardStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '150px',
    padding: '20px',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0,0,0,0.1)',
    boxSizing: 'border-box',
    width: '100%',
};

const iconStyle = {
    flex: '0 0 50px',
    height: '50px',
    marginRight: '20px'
};

const infoStyle = {
    flex: '1'
};

const boldTextStyle = {
    fontWeight: 'bold',
    marginBottom: '5px'
};

const valueStyle = {
    textAlign: 'right'
};

const CardInfo = ({ iconSrc, Descricao, Quantidade, Total }) => (
    <div style={cardStyle}>
        <img src={iconSrc} alt="Icon" style={iconStyle} />
        <div style={infoStyle}>
            <div style={boldTextStyle}>{Descricao}</div>
            {Quantidade > 0 ? <>
                <div>Quantidade</div>
                <div style={valueStyle}><b>{formatNumberInteiro(Quantidade)}</b></div>
            </> : null}
            <div>Total</div>
            <div style={valueStyle}><b>R$ {formatNumber(Total)}</b></div>
        </div>
    </div>
);

export default CardInfo;